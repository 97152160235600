import React, {useEffect} from 'react';
import "./Styles/Browser.css"
import i18n from "i18next";
import {Route, Routes} from "react-router-dom";
import * as RoutesName from "../../Routes/routes";
import Home from "./Pages/Home/Home";

const Browser = () => {

    useEffect(() => {
        i18n.language !== "fa" ? document.body.classList.add('ltr') : document.body.classList.remove('ltr');
        i18n.on("languageChanged", (lng) => {
            lng !== "fa" ? document.body.classList.add('ltr') : document.body.classList.remove('ltr');
        });
    }, []);

    return (
        <Routes>
            <Route path={RoutesName.Home} element={<Home/>}/>
        </Routes>
    );
};

export default Browser;
