import React from 'react';
import classes from './Home.module.css'
import LandingInfo from "./Module/LandingInfo/LandingInfo";
import LastPrice from "./Module/LastPrice/LastPrice";
import ScrollBar from "../../../../components/ScrollBar";

const Home = () => {
    return (
        <div className={`${classes.container} position-absolute width-100 px-8`}>
            <ScrollBar>
                <LandingInfo/>
                <LastPrice/>
            </ScrollBar>
        </div>
    );
};

export default Home;