import polbanLogoLight from "./src/polbanLogoLight.svg"
import arrow from "./src/arrow.svg"
import lightArrow from "./src/lightArrow.svg"

export const images = {

    polbanLogoLight:polbanLogoLight,
    arrow:arrow,
    lightArrow:lightArrow,

};
