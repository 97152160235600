import React from 'react';
import classes from './LandingInfo.module.css'
import {useTranslation} from "react-i18next";
import {images} from "../../../../../../assets/images/images";
import Button from "../../../../../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import Icon from "../../../../../../components/Icon/Icon";

const LandingInfo = () => {

    const {t} = useTranslation();
    const navigate = useNavigate()

    return (
        <div className={`height-100 width-40 ${classes.container} flex jc-center ai-center`}>


            <div className={`height-100 width-85 column jc-between`}>
                <div className={`row jc-between`}>
                    <div className={`column jc-start`}>
                        <span className={`fs-35 ${classes.title}`}>{t('title')}</span>
                        <span className={`text-gray fs-05 mt-4`}>{t('description')}</span>
                    </div>
                    <img src={images.polbanLogoLight} className={`${classes.logo}`} alt=""/>
                </div>
                <div className={`row width-100 row jc-between my-5`}>

                    <div className={`width-30 flex jc-start ai-start`}>

                        <Button
                            type="button"
                            buttonClass={`${classes.launchButton} cursor-pointer rounded-100-p`}
                            buttonTitle={t('LandingInfo.launchApp')}
                            onClick={()=>window.open(`${window.env.REACT_APP_APP_URL}`)}
                        />
                    </div>
                    <div className={`width-70 flex jc-center ai-center mt-4`}>
                        <img src={images.arrow} alt=""/>
                    </div>
                </div>
                <div className={`column`}>
                    <span className={`font-weight-bold fs-04`}>{t('services')}</span>
                    <div className={`row jc-start ai-start mt-3`}>

                        <div className={`column jc-center ai-center ml-1`}>
                            <div className={`${classes.box} flex jc-center ai-center rounded-8 mb-1`}>
                                <Icon
                                    iconName=" icon-transfer fs-20 text-white flex jc-center ai-center ml-1"
                                    iconClass={`cursor-pointer`}
                                />
                            </div>
                            <span className={`mt-1 fs-02`}>{t('transfer')}</span>
                        </div>
                        <div className={`column jc-center ai-center ml-1`}>
                            <div className={`${classes.box} flex jc-center ai-center rounded-8 mb-1`}>
                                <Icon
                                    iconName=" icon-exchange fs-35 text-white flex jc-center ai-center"
                                    iconClass={`cursor-pointer`}
                                />
                            </div>
                            <span className={`mt-1 fs-02`}>{t('exchange')}</span>
                        </div>
                        <div className={`column jc-center ai-center ml-1`}>
                            <div className={`${classes.box} flex jc-center ai-center rounded-8 mb-1`}>
                                <Icon
                                    iconName=" icon-transaction fs-35 text-white flex jc-center ai-center"
                                    iconClass={`cursor-pointer`}
                                />
                            </div>
                            <span className={`mt-1 fs-02`}>{t('transaction')}</span>
                        </div>

                    </div>
                </div>
            </div>



        </div>
    );
};

export default LandingInfo;
