import {useQuery} from "@tanstack/react-query";
import axios from "axios";


export const useGetDashboard = () => {

    return useQuery(
        ['dashboard'],
        async () => {
            const {data} = await axios.get(`/api/v1/dashboard`)
            return data?.data;
        },
        {
            staleTime: 5 * 60 * 1000,
        });
}