import React from 'react';
import classes from './LandingInfo.module.css'
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {images} from "../../../../../../assets/images/images";
import Button from "../../../../../../components/Button/Button";
import Icon from "../../../../../../components/Icon/Icon";

const LandingInfo = () => {


    const {t} = useTranslation();
    const navigate = useNavigate()

    return (
        <div className={`width-100 column pt-7`}>

            <div className={`row jc-between ai-start`}>
                <div className={`column jc-start`}>
                    <span className={`fs-35 ${classes.title}`}>{t('title')}</span>
                    <span className={`text-gray fs-05 mt-4`}>{t('description')}</span>
                </div>
                <img src={images.polbanLogoLight} className={`${classes.logo}`} alt=""/>
            </div>

            <div className={`row width-100 row jc-between mb-5`}>

                <div className={`width-60 column jc-start ai-start mt-5`}>
                        <span className={`font-weight-bold fs-04`}>{t('services')}</span>
                        <div className={`row jc-start ai-start mt-2`}>

                            <div className={`column jc-center ai-center ml-4`}>
                                <div className={`${classes.box} flex jc-center ai-center rounded-8 mb-1`}>
                                    <Icon
                                        iconName=" icon-transfer fs-06 text-white flex jc-center ai-center ml-2"
                                        iconClass={`cursor-pointer`}
                                    />
                                </div>
                                <span className={`mt-05 fs-02`}>{t('transfer')}</span>
                            </div>
                            <div className={`column jc-center ai-center ml-4`}>
                                <div className={`${classes.box} flex jc-center ai-center rounded-8 mb-1`}>
                                    <Icon
                                        iconName=" icon-exchange fs-27 text-white flex jc-center ai-center"
                                        iconClass={`cursor-pointer`}
                                    />
                                </div>
                                <span className={`mt-05 fs-02`}>{t('exchange')}</span>
                            </div>
                            <div className={`column jc-center ai-center ml-4`}>
                                <div className={`${classes.box} flex jc-center ai-center rounded-8 mb-1`}>
                                    <Icon
                                        iconName=" icon-transaction fs-27 text-white flex jc-center ai-center"
                                        iconClass={`cursor-pointer`}
                                    />
                                </div>
                                <span className={`mt-05 fs-02`}>{t('transaction')}</span>
                            </div>


                    </div>

                </div>
                <div className={`width-40 flex jc-center ai-start`}>
                    <img src={images.lightArrow} alt=""/>
                </div>
            </div>

            <div className={`width-100 flex jc-center ai-center`}>

                <Button
                    type="button"
                    buttonClass={`${classes.launchButton} cursor-pointer rounded-100-p`}
                    buttonTitle={t('LandingInfo.launchApp')}
                    onClick={()=>window.open(`${window.env.REACT_APP_APP_URL}`)}
                />
            </div>
        </div>
    );
};

export default LandingInfo;