import React from 'react';
import classes from './Coin.module.css'
import {useTranslation} from "react-i18next";
import {BN} from "../../../../../../../../utils/utils";

const Coin = ({data}) => {

    const {t} = useTranslation();

    const rateHandler = () => {
        if (!data?.rates) return <span>---</span>
        const newObject = [];
        Object.keys(data?.rates).forEach((key) => {
            newObject.push( <div className={`row `} key={key}><span className={`ml-1 text-gray fs-0-9`}>{key}</span>  <span>{data?.symbol === key ? 1 : data?.rates[key] !== null ? new BN(data?.rates[key]).decimalPlaces( data.precision).toFormat() : "---"}</span>   </div>)
        });
        return newObject;
    }

    return (
        <div className={`width-95 py-2 row jc-between ai-center ${classes.container}`}>
            {/* <div className={`${classes.circle} width-23 flex jc-start ai-center`}
                 style={{backgroundImage: `url("${data?.image_url}")`}}

            >

            </div>*/}

            <img src={data?.icon} alt="" className={`${classes.circle} width-23 flex jc-start ai-center`}/>

            <div className={`${classes.rectangle} width-76 row jc-between ai-center`}>
                <div className={`width-50 column jc-center ai-start`}>
                    <span className={``}>{data?.alias}</span>
                    <span className={``}>{data?.symbol}</span>
                </div>

                <div className={`width-50 column jc-center ai-end`}>
                    {rateHandler()}
                </div>
            </div>
        </div>
    );
};

export default Coin;