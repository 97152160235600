import React from 'react';
import classes from './LastPrice.module.css'
import {useTranslation} from "react-i18next";
import Coin from "./Module/Coin/Coin";
import {useGetCurrencies, useGetDashboard, useGetMarket, useGetRates} from "../../../../../../query";
import ScrollBar from "../../../../../../components/ScrollBar";

const LastPrice = () => {

    const {t} = useTranslation();
    /*const {data, isLoading, error} = useGetRates()*/

    const {data, isLoading, error} = useGetDashboard()

    const content = () => {
        /* if (isLoading) {
             return <span className={`my-10`}>{t("loading")}</span>
         }*/




        if (isLoading) {
            return <div className={`width-100 column jc-start ai-center height-100 my-1`}>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-85 rounded-8`}/>
                </div>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-85 rounded-8`}/>
                </div>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-85 rounded-8`}/>
                </div>
            </div>
        }
        if (error) {
            return <div className={`width-100 column jc-center ai-center height-100 my-1`}>
                <span className={`my-10`}>{t("error")}</span>
            </div>
        }
        if (Object.keys(data).length <= 0) {
            return <div className={`width-100 column jc-center ai-center height-100 my-1`}>
                <span className={`my-10`}>{t("noData")}</span>
            </div>
        }
        return Object.keys(data).map(key => <Coin data={data[key]} key={key}/>)
    }


    return (
        <div className={`height-100 width-57 ${classes.container}`}>

            <div className={`font-weight-bold fs-02 height-10`}>{t('lastPrice')}</div>


            <div className={`column jc-center ai-center ${classes.content} height-90 rounded-8`}>
                <ScrollBar>
                    {content()}
                </ScrollBar>
            </div>





        </div>
    );
};

export default LastPrice;
