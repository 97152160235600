import React from 'react';
import classes from './Home.module.css'
import LandingInfo from "./Module/LandingInfo/LandingInfo";
import LastPrice from "./Module/LastPrice/LastPrice";

const Home = () => {
    return (
        <div className={`${classes.container} position-absolute width-100 row jc-center ai-center`}>
            <div className={`row jc-between height-70 width-85`}>
                <LandingInfo/>
                <LastPrice/>
            </div>
        </div>
    );
};

export default Home;
