export default function setupAxios(axios, auth) {
    axios.defaults.baseURL = window.env.REACT_APP_API_BASE_URL;
    axios.defaults.headers.Accept = 'application/json';
    axios.defaults.timeout = 30000;
    axios.interceptors.request.use(
        (config) => {
            const accessToken = auth?.token
            /*if (message === "offline") {
                throw new axios.Cancel('Operation canceled by the user.');
            }*/
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`
            }
            return config
        },
        (err) => Promise.reject(err)
    )

}
