import React from 'react';
import {isBrowser, isMobile, isTablet} from "react-device-detect";
import Browser from "./Browser/Browser";
import Mobile from "./Mobile/Mobile";
import setupAxios from "../setup/axios/setupAxios";
import axios from "axios";

const Main = () => {

    setupAxios(axios);

    const detectDevice = () => {
        if (isBrowser) return <Browser/>
        if (isTablet) return <Mobile/>
        if (isMobile) return <Mobile/>
    }

    return detectDevice()


};

export default Main;
